import React from 'react';
import {
  Layout,
  Footer,
  Content,
} from '../components';

// styles import
import '../styles/index.css';

const IndexPage = () => {
  return (
    <Layout>
      <div className={'content'}>
        <span style={{display: 'flex', flex: 0.25}} />
        <Content />
        <Footer />
      </div>
    </Layout>
  )
};

export default IndexPage
